.ProductList {
  display: inline-flex;
  flex-wrap: wrap;
  padding-left: 10px;
}

.Product {
     width: 304px;;
  height: fit-content;
  margin-left: 10px;
  margin-top: 10px;
  background-color: white;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  padding: 10px;
  background-color: white;
  display: inline-block;
}
.Product:hover{
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.Product img {
  width: 267px;
  height: 214px;
  margin: auto;
  margin-left: 6px;
  margin-top: 14px;
  background-color: #f9f0eb;
}
.pro_name{
  font-weight: bold;
  text-align: center;
}
.pro_img{
 
  height: 60%;
}
.pro_price{
  margin-top: 15px;
  
  text-align: center;
}
.pro_quntity{
  display: inline-flex;
  flex-wrap: wrap;
  margin-left: 6px;
  margin-top: 10px;
 
}
.quan_data{
  background-color: rgb(220, 212, 212);
  color: rgb(93, 93, 93);
  height: 33px;
  width: 50px;
  margin-right: 5px;
  margin-top: 10px;
  font-size: 1vw;
  display: flex;
  cursor: pointer;
}
.quan_name{
  margin: auto;
  font-size: 13px;
}
.quan_data:hover{
  background-color: brown;
  color: white;
}

@media only screen and (max-width: 600px) {
.Product{
 width: 43vw; 
}
.Product img {
 width: 90%;
 height: fit-content;
}
.link_item {
  font-size: 12px;
}
.pro_name {
  
  FONT-SIZE: 12PX;
}
}