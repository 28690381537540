.bottom_of_page {
  width: 100%;
  height: 250px;
  width: 100%;
  background-color: #591400;
  margin-top: 50px;
  display: flex;
}

.b_middle {
  width: 53%;

  margin: auto;
  height: 250px;
}
.b_page_links {
  width: 15%;
  display: inline;
  margin-top: 40px;
  margin-left: 60px;
}
.b_page_link {
  background-color: #591400;
  border: 1px solid white;
  width: 102%;
  height: 24px;
  margin-bottom: 10px;
  font-family: Times, Times New Roman, serif;
  display: flex;
  color: white;
}

.b_page_link:hover {
  background-color: #ffffff;
  color: #591400;
  cursor: pointer;
  border: 1px solid #ffc401;
}

.pages_link {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 30px;
}

.b_left {
  height: 161px;
  right: 0;
  margin-top: 40px;
  float: right;
  display: flex;
  width: 300px;
  color: #ffffff;
}

.sl_firstrow {
  height: 50%;
  width: 100%;
  background-color: #591400;
  margin: auto;
}
.sl_secrow {
  height: 50%;
  width: 100%;
  background-color: #591400;
  display: flex;
  margin: autos;
}

.social_m_link {
  height: 161px;
  width: 300px;
  margin: auto;
  display: flex;
}
.facebook {
  width: 80px;
  height: 60px;
  background-color: #591400;
  float: left;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  border-style: solid;
  border-width: 0.5px;
  border-color: white;
}
.logo-facebook {
  width: 17px;
  height: 33px;
  margin: auto;
}
.whatsapp {
  width: 80px;
  height: 60px;
  background-color: #591400;
  margin: auto;
  display: flex;
  border-style: solid;
  border-width: 0.5px;
  border-color: white;
  text-decoration: none;
}
.whatsapp_img {
  width: 28px;
  height: 30px;
  margin: auto;
}
.youtube {
  width: 80px;
  height: 60px;
  background-color: #591400;
  float: right;
  margin-top: auto;
  margin-bottom: auto;
  border-style: solid;
  border-width: 0.5px;
  border-color: white;
  display: flex;
}

.youtube_img {
  width: 32px;
  height: 28px;
  margin: auto;
}
.youtube_img:hover {
}

.facebook-custom,
.youtube-custom,
.whatsapp-custom {
  background-color: #591400;
  border-radius: 1px;
  color: white;
  width: 80px;
  padding-top: 5px;
}

.section1 {
  padding: 1em;
  background-color: lime;
  margin-bottom: 1em;
}

.facebook-custom:hover {
  color: #591400;
  background-color: #ffffff;
}
.facebook:hover {
  background-color: #ffffff;
}
.whatsapp:hover {
  background-color: #ffffff;
}
.youtube:hover {
  background-color: #ffffff;
}
.whatsapp-custom:hover {
  color: #591400;
  background-color: #ffffff;
}
.youtube-custom:hover {
  color: #591400;
  background-color: #ffffff;
}
.fa-facebook:before {
  content: "\f09a";
  margin-left: 23px;
}
.fa-whatsapp:before {
  content: "\f09a";
  margin-left: 20px;
}
.fa-youtube:before {
  content: "\f09a";
  margin-left: 20px;
}
.company_email {
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  text-decoration: none;
}
.company_email:hover {
  opacity: 85%;
}
.company_mobile {
  margin-left: 40%;
  cursor: context-menu;
}

.b_right {
  color: white;
  margin-top: 40px;
  display: flex;
  width: 35%;
}
footer {
  text-align: center;
  font-family: "Times New Roman", Times, Baskerville, Georgia, serif;
  font-size: 12px;
  padding: 2px;
  color: black;

  margin: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  left: 0;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
  border: 1px solid #dedede;

  display: flex;
}
.footer_title {
  margin: auto;
}
.contact_topics {
  font-weight: bold;
  text-decoration: none;
  color: white;
}
.contact_topics:hover {
  font-weight: bold;
  text-decoration: none;
  color: white;
  cursor: context-menu;
}
.company_address {
  cursor: context-menu;
}
@media only screen and (max-width: 768px) {
.b_page_links {
  display: none;
}

.bottom_of_page {
  display: block;
  height: 356px;
}

.b_left {
  height: 7px;
  width: 100%;
  margin-top: 0;
}

.social_m_link {
  height: 85px;
  width: 300px;
  margin: auto;
  display: flex;
  margin-left: -54px;
}

.company_mobile {
  margin: auto;
  margin-top: 97px;
  font-size: 13px;
  text-align: center;
}

.company_email {
  margin-top: 94px;
  font-size: 13px;
}

.social_m_link {
  width: 253px;

  margin: auto;
}

.sl_secrow {
  width: 100%;
  height: 126px;
}

.b_page_link {
  display: none;
}

.b_right {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  margin-top: -80px;
  display: flex;
}

.company_address {
  margin: auto;
  font-size: 13px;
  width: 50%;
  text-align: center;
  cursor: context-menu;
}

.b_middle {
  width: 100%;
}

.contact_topics {
  font-weight: bold;
  text-decoration: none;
  color: white;
}
  }
@media only screen and (max-width: 600px) {
  .b_page_links {
    display: none;
  }
  .bottom_of_page {
    display: block;
    height: 356px;
  }
  .b_left {
    height: 7px;
    width: 100%;
    margin-top: 0;
  }
  .social_m_link {
    height: 85px;
    width: 300px;
    margin: auto;
    display: flex;
    margin-left: -54px;
  }
  .company_mobile {
    margin: auto;
    margin-top: 97px;
    font-size: 13px;
    text-align: center;
  }
  .company_email {
    margin-top: 94px;
    font-size: 13px;
  }
  .social_m_link {
    width: 253px;

    margin: auto;
  }
  .sl_secrow {
    width: 100%;
    height: 126px;
  }
  .b_page_link {
    display: none;
  }
  .b_right {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    margin-top: -80px;
    display: flex;
  }
  .company_address {
    margin: auto;
    font-size: 13px;
    width: 50%;
    text-align: center;
    cursor: context-menu;
  }
  .b_middle {
    width: 100%;
  }
  .contact_topics {
    font-weight: bold;
    text-decoration: none;
    color: white;
  }
}
