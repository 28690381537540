.body_home {
  width: 100%;
  height: fit-content;

  z-index: 1;
}
.img_pro {
  width: 100%;
  height: fit-content;
}
.topic_welcome {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  color: #6c250c;
  font-size: 28px;
  font-weight: bold;
}
.welcome_message {
  width: 65vw;
  background-color: #eeeeef;
  height: auto;
  text-align: center;
  margin: auto;
  display: flex;
  margin-bottom: 29px;
  font-size: 17px;
  padding: 25px;
}
.body_products_btns {
  height: 413px;
  width: 80%;
}
.button_body {
  width: 100%;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  margin-left: auto;
  flex-wrap: wrap;
  margin-right: auto;
  height: -moz-fit-content;
  height: fit-content;
  /* display: flex; */
  /* margin: auto; */
  align-items: center;
  text-align: center;
  margin-top: 10px;
}
.btn_pro {
  background-color: #b45f39;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  width: 280px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}
.btn_pro:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #da9c17;
}

.product_btns {
  width: 100%;
  height: 400px;
  margin-top: 10px;
  background-color: white;
}
.product_button_image {
  opacity: 15%;
  height: 279px;
  width: 87%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  position: absolute;
}
.product_btn_first_raw {
  width: 100%;
  height: 100px;
  position: relative;
  padding-top: 15px;
  display: flex;
}
.product_btn_sec_raw {
  width: 100%;
  height: 100px;
  position: relative;
  padding-top: 15px;
  display: flex;
}
.product_btn_third_raw {
  width: 100%;
  height: 100px;
  position: relative;
  padding-top: 15px;
  display: flex;
}
.product_btn_forth_raw {
  width: 100%;
  height: 100px;
  position: relative;
  padding-top: 15px;
  display: flex;
}
.btn {
  position: absolute;
  background-color: #b45f39;
  border: #ffffff;
  width: 31vh;
  height: 49px;
  margin: auto;
  margin-left: 4%;
  float: left;
  font-size: 1rem;
}
.btn:hover {
  background-color: #ffd900;
  color: #000000;
}
.pbfr_btn_1 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.pbfr_btn_2 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.pbfr_btn_3 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.pbsr_btn_1 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.pbsr_btn_2 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.pbsr_btn_3 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.pbtr_btn_1 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  float: right;
}
.pbtr_btn_2 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.pbtr_btn_3 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.pbfor_btn_1 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  float: right;
}
.pbfor_btn_2 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}

.pbfor_btn_3 {
  width: 33.3%;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.home_btn {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
  text-decoration: none;
}
.company_btn {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
}
.about_us_btn {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.products_btn {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
}
.careers_btn {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.btn_homet {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.nav_bar_middle_side {
  display: flex;
  width: 30%;
  margin-top: -5px;
}
.navebar_lesftside {
  width: 42%;

  display: flex;
}
.navebar_middleside {
  width: 22%;
}
.navebar_right_side {
  width: 19%;
  display: flex;
}
.contact_btn {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.p_buttons {
  flex-wrap: wrap;
  width: 81%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  margin: auto;
}
.button_display {
  width: 70%;
  height: 576px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  background-color: aqua;
  flex-wrap: wrap;
  right: 0;
}
.product_btn_wall {
  display: flex;
}

.latest_events {
  height: 250px;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
  border: 1px solid #dedede;
  background: -moz-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #454545 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background: -webkit-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #bdbdbd 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background: -o-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #808080 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background: linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #c2bdbd 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  margin-top: 45px;
}

.latest_event {
  background-color: #0c4707;
  margin: auto;
  height: 50px;
  width: 300px;
  margin-top: 50px;
  font-family: Helvetica, sans-serif;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.latest_event_name {
  color: white;
  margin: auto;
  font-size: 20px;
}

.l_event {
  place-items: center;

  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: auto;
  margin-bottom: auto;

  min-height: 25vh;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
  border: 1px solid #dedede;

  background: -moz-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #454545 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background: -webkit-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #bdbdbd 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background: -o-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #808080 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background: linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #c2bdbd 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );

  margin-top: 45px;
}

.slider {
  height: 250px;
  margin: auto;
  position: relative;
  width: 90%;
  display: grid;
  place-items: center;
}
.slider_track {
  display: flex;
  width: calc(250px * 7);
  animation: scroll 40% linear infinite;
  height: 235px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slides {
  height: 200px;
  display: flex;
  padding: 15px;
  padding-right: 20px;
  perspective: 100px;
}
.img {
  width: 100%;
  transition: transform 1s;
}

.img:hover {
  transform: translateZ(20px);
}

@media only screen and (max-width: 1199px) {
.topic_welcome{
  margin-top: 39px;
}
}
@media only screen and (max-width: 768px) {
  .topic_welcome {
    margin-top: 54px;
  }
  .btn {
    position: absolute;
    background-color: #b45f39;
    border: #ffffff;
    width: 20vh;
    height: 49px;
    margin: auto;
    margin-left: 4%;
    float: left;
    font-size: 2vh;
  }
}

@media only screen and (max-width: 600px) {
  .pbfor_btn_1 {
    width: 33.3%;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .product_btn_first_raw {
    height: 150px;
    display: block;
    text-align: center;
  }
  .product_btn_sec_raw {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .product_btn_third_raw {
    display: block;
    text-align: center;
  }
  .pbtr_btn_1 {
    text-decoration: none;
    float: inherit;
  }
  .product_btn_forth_raw {
    display: block;
    text-align: center;
  }
  .btn {
    height: 43px;
    width: 75%;
  }
  .pbfor_btn_1 {
    text-decoration: none;
    float: inherit;
  }
  .product_btn_sec_raw {
    height: 151px;
    text-align: center;
  }
  .product_btn_third_raw {
    text-align: center;
    height: 151px;
  }
  .product_btns {
    height: 536px;
  }
  .product_button_image {
    height: 576px;
  }
  .btn {
    margin-left: 0px;
    font-size: 15px;
  }
  .topic_welcome {
    margin-top: 54px;
    font-size: 19px;
    font-weight: bold;
  }
  .company_welcome_pro {
    font-size: 12px;
  }
  .latest_event {
    background-color: #0c4707;

    height: 36px;
    width: 250px;
    margin-top: 77px;
  }
  .latest_event_name {
    font-size: 15px;
  }
  .l_event {
   min-height: 21vh;;
    margin-top: 28px;
  }
  .slides {
    height: 251px;
    width: 337px;
    display: contents;
    padding: 33px;
    padding-right: 39px;
    perspective: 100px;
  }
  .slider {
    height: 216px;
    margin: 28px;
    position: inherit;
    width: 90%;
    display: grid;
    place-items: center;
  }
  .img {
    margin-right: 60px;
  }
  .slider_track {
  
    width: calc(250px *4);
   
  }
}
