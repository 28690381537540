.topic_para {
  font-size: x-large;
  margin: auto;
}
.topic {
  margin: auto;
  height: 50px;
  width: -moz-fit-content;
  width: fit-content;
  color: rgb(0, 0, 0);
  font-family: Helvetica, sans-serif;
  margin-bottom: 1px;
  font-weight: bold;
  margin-top: 1px;
}

.career_page_body {
  width: 80%;
  height: 802px;
  margin: auto;
  display: flex;
}
.left_side {
  width: 50%;
  height: 800px;
}
.right_side {
}
.career_img {
  height: fit-content;
  width: 100%;
  padding-top: 30px;
}
.contact_info {
  padding-top: 30px;
  margin-left: 30px;
}
.file-upload {
  padding-top: 20px;
  width: 80%;
  padding-bottom: 30px;
}
.file-upload-wrapper {
  display: flex;
}
.myfile {
  font-size: larger;
  font: 1rem "Fira Sans", sans-serif;
}

.left_img{
 width: 100%;
 height: fit-content;
}
.c_left{
  width: 80%;
  margin: auto;
  margin-top: 45px;
}

@media only screen and (max-width: 768px) {
  .career_img {
    display: none;
  }

  .file-upload-wrapper {
    display: block;
  }

  .left_side {
    width: 50%;
    display: none;
    height: 800px;
  }

  .topic {
    margin-top: 71px;
    margin-bottom: 1px;
  }

  .career_page_body {
    text-decoration: none;
    height: 722px;
  }
  .topic_para {
    margin-top: 10vw;
  }
}

@media only screen and (max-width: 600px) {
  .career_img {
    display: none;
  }
  .file-upload-wrapper {
    display: block;
  }
  .left_side {
    width: 50%;
    display: none;
    height: 800px;
  }
  .topic {
    margin-top: 71px;
    margin-bottom: 1px;
  }
  .career_page_body {
    text-decoration: none;
    height: 722px;
  }
}
