.top_main {
  background-image: linear-gradient(white, rgb(180 177 182));
  box-shadow: 2px 2px 0.5em rgb(122 122 122 / 55%),
    inset 1px 1px 0 rgb(255 255 255 / 90%), inset -1px -1px 0 rgb(0 0 0 / 34%);
  border: 1px solid #dedede;
  opacity: 29%;
  width: 100%;
  height: 550px;
  position: absolute;
}
.top_main_navebar {
  position: absolute;
  left: 0;
  display: flex;
  top: 50px;
  width: 100%;
  height: 50px;
  background-color: #591400;
}

.left_chakra {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 400px;
  height: 400px;
}
.right_chakra {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  height: 400px;
}
.top_main_name {
  width: 400px;
  height: 50px;
  background-image: linear-gradient(white, rgb(248, 183, 63));
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0%;
  border-bottom-left-radius: 70px;
  border-bottom-right-radius: 70px;
  margin-left: auto;
  margin-right: auto;
}
.brand_name {
  font-size: 20rem;
  top: -17%;
  position: absolute;
  margin: auto;
  margin-left: auto;
  margin-right: auto;
  left: 123px;
  right: 0;
}
.branding_name {
  left: 0;
  right: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}
router-link {
  text-decoration: none;
}
.brandengname {
  padding-top: 1px;
  width: 238px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.brand_name_english {
  height: 50px;
  background-color: #591400;
  color: #eed2b3;
  padding-top: 5px;
  margin-right: auto;
  font-size: 26px;
  float: left;

  text-align: center;
  font-family: "Titillium Web", sans-serif;
  width: 251px;
  left: 0;
  right: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
}

.slide_show {
  width: 75%;
  height: 400px;
  background-color: black;
  position: absolute;
  left: 0;
  right: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  z-index: 3;
}
.slide_show_items {
  left: 0;
  right: 0;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: 75%;
  height: 400px;
}

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 400px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.brand_logo {
  position: absolute;
  width: 100px;
  height: 100px;
  /* background-color: aqua; */
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 5;
}
.each-slide {
  width: 100%;
  height: 100%;
}

.link_to {
  text-decoration: none;
  color: inherit;
}

.link_to:hover {
  color: inherit;
}

.btn_home {
  height: 50px;
  width: 150px;
  background-color: #591400;
  color: #ffffff;
  padding: 10px;
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  padding-top: 15px;
  margin-left: 30px;
}
.header {
  display: flex;
  height: 600px;
}

.btn_home:hover,
.btn_home:active{
  background-image: linear-gradient(white, rgb(248, 183, 63));
  color: #591400;
  cursor: pointer;
}
.btn_home.active { background-color: linear-gradient(white, rgb(248, 183, 63)); }
 

.btn_contacts {
  height: 50px;
  width: 150px;
  background-color: #591400;
  color: #ffffff;
  padding: 10px;
  font-family: "Titillium Web", sans-serif;
  margin-left: 1px;
  font-size: 14px;
  padding-top: 15px;
  padding-top: 15px;
}

.btn_contacts:hover {
  background-image: linear-gradient(white, rgb(248, 183, 63));
  color: #591400;
  cursor: pointer;
}

.btn_careers:hover {
  background-image: linear-gradient(white, rgb(248, 183, 63));
  color: #591400;
  cursor: pointer;
}

.btn_Company {
  height: 50px;
  width: 150px;
  background-color: #591400;
  color: #ffffff;
  padding: 10px;
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  padding-top: 15px;
}

.btn_Company:hover {
  background-image: linear-gradient(white, rgb(248, 183, 63));
  color: #591400;
  cursor: pointer;
}

.btn_products:hover {
  background-image: linear-gradient(white, rgb(248, 183, 63));
  color: #591400;
  height: 60px;
  cursor: pointer;
}

.btn_about_us:hover {
  background-color: linear-gradient(white, rgb(248, 183, 63));
  color: #591400;
  cursor: pointer;
  text-decoration: none;
}

.btn_about_us {
  height: 50px;
  width: 150px;
  background-color: #591400;
  color: #ffffff;
  padding-top: 15px;

  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
}

.btn_products {
  height: 50px;
  width: 150px;
  background-color: #591400;
  color: #ffffff;
  padding: 10px;

  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
  padding-top: 15px;
}

.btn_careers {
  height: 50px;
  width: 132px;
  background-color: #591400;
  color: #ffffff;
  padding-top: 15px;

  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
}

.btn_contacts {
  height: 50px;
  background-color: #591400;

  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
}

.nav_heading {
  color: #591400;
  margin-left: auto;
  padding-left: 42px;
  padding-top: 10px;
  margin: auto;
}
.dropdown_company a:hover {
  color: #591400;
  background-color: rgb(255, 205, 112);
}
.dropdown_products a:hover {
  color: #591400;
  background-color: rgb(255, 205, 112);
  width: 230px;
}

.dropdown_products a {
  float: none;
  color: #ffffff;
  background-color: #591400;
  text-align: center;
  padding: 11px 30px;
  text-decoration: none;
  text-align: left;
  width: 230px;
  margin-left: -3px;
  margin-top: -5px;
  display: block;

  visibility: visible;
  display: block;
  z-index: 500 !important;
  position: relative;
}

.btn_products:hover .dropdown_products {
  display: block;
  overflow: hidden;
 
}

.dropdown_products {
  display: none;
  text-decoration: none;
  float: none;
  color: #ffffff;
  text-align: center;
  padding: 15px 30px;
  text-decoration: none;
  text-align: left;
  width: 230px;
  margin-top: -2px;
  margin-left: -37px;
  margin-top: -5px;
  visibility: visible;
  z-index: 500 !important;
  position: relative;
}

.btn_Company:hover .dropdown_company {
  display: block;
  overflow: hidden;
}

.dropdown_company a {
  float: none;
  color: #ffffff;
  background-color: #591400;
  text-align: center;
  padding: 11px 30px;
  text-decoration: none;
  text-align: left;
  width: 230px;
  margin-left: -3px;
  margin-top: -5px;
  display: block;

  visibility: visible;
  display: block;
  z-index: 500 !important;
  position: relative;
}

.dropdown_company a:hover {
  color: #591400;
}

.dropdown_company {
  display: none;
  text-decoration: none;
  float: none;
  color: #ffffff;
  text-align: center;
  padding: 5px 30px;
  text-decoration: none;
  text-align: left;
  width: 230px;
  margin-top: -2px;
  margin-left: -37px;
  margin-top: -5px;
  visibility: visible;
  z-index: 500 !important;
  position: relative;
}
.checkbtn {
  font-size: 30px;
  color: #ffffff;
  float: left;
  padding-left: 25px;
  cursor: pointer;
  display: none;
  background-color: #591400;
  width: 100%;
  height: 45px;
  padding-top: 6px;
}
#check {
  display: none;
}


@media only screen and (max-width: 1199px) {
  
.btn_products:hover .dropdown_products {
  display: none;
 
}
.btn_products:hover {
  height:50px;
  
}
  .top_main_navebar {
    display: block;
  }

  .top_main_navebar .navebar_lesftside {
    display: block;
    width: 100%;
  }

  .top_main_navebar .navebar_right_side {
    display: block;
    width: 100%;
  }

  .navebar_middleside {
    display: none;
  }

  .link_to {
    background: black;
    width: 100%;
  }

  .btn_home {
    margin: auto;
    width: 100%;
  }

  .btn_Company {
    width: 100%;
    margin: auto;
  }

  .btn_products {
    width: 100%;
    margin: auto;
  }

  .btn_contacts {
    width: 100%;
    margin-left: 0;
  }

  .btn_careers {
    width: 100%;
  }

  .left_chakra {
    width: 210px;
    height: 210px;
  }

  .right_chakra {
    width: 210px;
    height: 210px;
  }

  .top_main_name {
    width: 100%;
  }

  .slideImages {
    width: 100%;
    height: 100%;
  }

  .each-slide > div {
    height: 241px;
  }

  .slide_show {
    height: 146px;
    margin-top: 95px;
  }

  .brand_logo {
    width: 60px;
    height: 60px;
    margin-top: 35px;
  }

  .header {
    display: flex;
  }

  .top_main {
    height: 380px;
  }

  .header {
    height: 361px;
  }

  .checkbtn {
    display: block;
    position: absolute;
    margin-top: 50px;
  }

  .top_main_navebar {
    transition: all 0.5s;
    left: -100%;
    margin-top: 45px;
    z-index: 10;
    background-color: #591400;
  }

  #check:checked ~ .top_main_navebar {
    left: 0;
  }

  #check:checked ~ .brand_logo {
    display: none;
  }

  .btn:hover {
    text-decoration: none;
    background-image: #591400;
  }
  .dropdown_company {
    margin: auto;
    text-decoration: none;
  }

  .btn_home:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }

  .btn_Company:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }

  .btn_products:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }

  .btn_contacts:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }

  .btn_careers:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }
}
@media only screen and (max-width: 600px) {
  .top_main_navebar {
    display: block;
  }
  .top_main_navebar .navebar_lesftside {
    display: block;
    width: 100%;
  }

  .top_main_navebar .navebar_right_side {
    display: block;
    width: 100%;
  }
  .navebar_middleside {
    display: none;
  }

  .link_to {
    background: black;
    width: 100%;
  }
  .btn_home {
    margin: auto;
    width: 100%;
    text-decoration: none;
  }

  .btn_Company {
    width: 100%;
    margin: auto;
  }
  .btn_home:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }
  .btn_Company:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }
  .btn_products:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }
  .btn_contacts:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }
  .btn_careers:hover {
    text-decoration: none;
    background-image: none;
    color: silver;
  }
  .btn_products {
    width: 100%;
    
  }
  .btn_contacts {
    width: 100%;
    margin-left: 0;
  }
  .btn_careers {
    width: 100%;
  }
  .left_chakra {
    width: 210px;
    height: 210px;
  }
  .right_chakra {
    width: 210px;
    height: 210px;
  }
  .top_main_name {
    width: 100%;
  }
  .slideImages {
    width: 100%;
    height: 100%;
  }
  .each-slide > div {
    height: 241px;
  }
  .slide_show {
    height: 146px;
    margin-top: 95px;
  }
  .brand_logo {
    width: 60px;
    height: 60px;
    margin-top: 35px;
  }
  .header {
    display: flex;
  }
  .top_main {
    height: 380px;
  }
  .header {
    height: 361px;
  }
  .checkbtn {
    display: block;
    position: absolute;
    margin-top: 50px;
    
  }
  .top_main_navebar {
    transition: all 0.5s;
    left: -100%;
    margin-top: 45px;
    z-index: 10;
    background-color: #591400;
  }
  #check:checked ~ .top_main_navebar {
    left: 0;
  }
  #check:checked ~ .brand_logo {
    display: none;
  }
  .btn:hover {
    text-decoration: none;
    background-image: #591400;
  }
 
 
}
