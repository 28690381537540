.our_mission {
  margin: auto;
  padding-top: 20px;
  color: #5a2424;
  font-size: 28px;
  font-weight: bold;

  padding-left: 30px;
  width: 80%;
}


.rightside {
  width: 80%;
}

.mission_body {
  width: 80%;
  height: fit-content;
  display: flex;
  margin: auto;
  margin-bottom: 40px;
  margin-top: 15px;
}
.mision_img {
  width: 20%;
  height: fit-content;
  padding-left: 30px;
}


.goc {
  margin: auto;
  height: 50px;

  color: white;
  font-family: Helvetica, sans-serif;
  margin-bottom: 53px;
  display: flex;
}
.gofcom {
  margin: auto;
  color: #5a2424;
  font-size: 28px;
  font-weight: bold;
  font-family: Helvetica, sans-serif;
  margin-top: 52px;
}
.company_groups {
  width: 100%;
  height: 250px;
  display: flex;
  margin-top: 15px;
}
.companies_1 {
  text-decoration: none;
  width: 16.66%;
  background-color: rgb(60, 133, 167);
  height: 80%;
  margin: 10px;
  display: flex;

  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
  display: flex;
}
.companies_1:hover {
  background-color: rgb(170, 113, 94);
}
.companies_2:hover {
  background-color: rgb(170, 113, 94);
}
.companies_3:hover {
  background-color: rgb(170, 113, 94);
}
.companies_4:hover {
  background-color: rgb(170, 113, 94);
}
.companies_5:hover {
  background-color: rgb(170, 113, 94);
}
.companies_6:hover {
  background-color: rgb(170, 113, 94);
}

.compa {
  text-decoration: none;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
}

.companies_2 {
  width: 16.66%;
  background-color: rgb(211, 67, 67);
  height: 80%;
  margin: 10px;
  display: flex;

  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
}
.companies_3 {
  width: 16.66%;
  background-color: rgb(133, 220, 107);
  height: 80%;
  margin: 10px;
  display: flex;

  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
}
.companies_4 {
  width: 16.66%;
  background-color: rgb(154, 154, 154);
  height: 80%;
  margin: 10px;
  display: flex;
  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
}
.companies_5 {
  width: 16.66%;
  background-color: rgb(250, 157, 102);
  height: 80%;
  margin: 10px;
  display: flex;

  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
}
.companies_6 {
  width: 16.66%;
  background-color: rgb(66, 37, 72);
  height: 80%;
  margin: 10px;
  display: flex;

  box-shadow: 2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34);
}

.company_name {
  font-size: 1.7vw;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: rgb(255, 255, 255);
  margin: auto;
  text-align: center;
  padding: 10px;
  margin: auto;
}

.company_imgs {
  width: 70%;
  height: 70%;
  display: flex;
}

.cards {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: auto;
}

.card_squre {
  margin: 24px;
  position: relative;
  max-width: 224px;
  max-height: 350px;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}

.card-title1 {
     display: block;
    text-align: center;
    color: #fff;
    background-color: #6184a8;
    padding: 6%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-size: 1.2rem;
    width: 100%;
}
.card-title2 {
  display: block;
  text-align: center;
  color: #fff;
  background-color: #ad1f28;
  padding: 6%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 1.2rem;
  width: 100%;
}
.card-title3 {
  display: block;
  text-align: center;
  color: #fff;
  background-color: #3cb250;
  padding: 6%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 1.2rem;
  width: 100%;
}
.card-title4 {
  display: block;
  text-align: center;
  color: #fff;
  background-color: #f38302;
  padding: 6%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 1.2rem;
  width: 100%;
}
.card-title5 {
  display: block;
  text-align: center;
  color: #fff;
  background-color: #4b4b4b;
  padding: 6%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 1.2rem;
  width: 100%;
}
.card-title6 {
  display: block;
  text-align: center;
  color: #fff;
  background-color: #422548;
  padding: 6%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-size: 1.2rem;
  width: 100%;
}
.card_squre img {
  width: 100%;
  height: 98%;
  object-fit: cover;
  display: block;
  position: relative;
}

.card-desc1 {
  display: block;
  font-size: 0.9rem;
  position: absolute;
  height: 0;
  top: 0;
  opacity: 0;
  padding: 18px 8%;
  background-color: #6184a8;
  color: white;

  transition: 0.8s ease;
}

.card_squre:hover .card-desc1 {
  opacity: 1;
  height: 100%;
  cursor: pointer;
}
.card-desc2 {
  display: block;
  font-size: 0.9rem;
  position: absolute;
  height: 0;
  top: 0;
  opacity: 0;
  padding: 18px 8%;
  background-color: #ad1f28;
  color: white;
  transition: 0.8s ease;
}
a {
  text-decoration: none;
}
.card_squre:hover .card-desc2 {
  opacity: 1;
  height: 100%;
  cursor: pointer;
}
.card-desc3 {
  display: block;
  font-size: 0.9rem;
  position: absolute;
  height: 0;
  top: 0;
  opacity: 0;
  padding: 18px 8%;
  background-color: #3cb250;
  color: white;

  transition: 0.8s ease;
}

.card_squre:hover .card-desc3 {
  opacity: 1;
  height: 100%;
  cursor: pointer;
}
.card-desc4 {
  display: block;
  font-size: 0.9rem;
  position: absolute;
  height: 0;
  top: 0;
  opacity: 0;
  padding: 18px 8%;
  background-color: #f38302;
  color: white;

  transition: 0.8s ease;
}

.card_squre:hover .card-desc4 {
  opacity: 1;
  height: 100%;
  cursor: pointer;
}
.card-desc5 {
  display: block;
  font-size: 0.9rem;
  position: absolute;
  height: 0;
  top: 0;
  opacity: 0;
  padding: 18px 8%;
  background-color: #4b4b4b;
  color: white;

  transition: 0.8s ease;
}

.card_squre:hover .card-desc5 {
  opacity: 1;
  height: 100%;
  cursor: pointer;
}
.card-desc6 {
  display: block;
  font-size: 0.9rem;
  position: absolute;
  height: 0;
  top: 0;
  opacity: 0;
  padding: 18px 8%;
  background-color: #422548;
  color: white;

  transition: 0.8s ease;
}

.card_squre:hover .card-desc6 {
  opacity: 1;
  height: 100%;
  cursor: pointer;
}
h1 {
  font-size: 2.8rem;
  color: #fff;
  margin: 40px 0 20px 0;
  text-align: center;
}

.team_of_company{
  height: 500px;
  width: 82%;
    margin: auto;
  background-color:#ecebec;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  padding-bottom: 51px;
}
.teambox{
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  width: 184px;
  margin-top: 45px;
  padding: 20px;
  background-color: #fff;
  display: inline;
  border-radius: 3%;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;}
.circle{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 7px solid #e9e8ea;;
}
.team_posts{
  
  font-size: 20px;
 
  text-align: center;
  margin-top: 9px;
  font-family: Sora;
}
.team_position{
  font-family: cursive;
  text-align: center;
  font-size: 13px;
  /* background-color: #e36754; */
  color: #591400;
  font-weight: bold;
}
.team_msgs{
  font-family: cursive;
  text-align: center;

}
.team_title{
  text-align: center;
  color: #5a2424;
  font-size: 28px;
  font-weight: bold;
  font-family: Helvetica, sans-serif;
}
.missionofcompany{
  height: 36VW;
  margin: auto;
  width: 76%;
  margin: auto;
 
  background-image: linear-gradient(to left, white, rgb(235 234 236));
  display: flex;
}


@media only screen and (max-width: 1199px){
  .missionofcompany{
    margin-top:60px;
  }
}
@media only screen and (max-width: 768px) {
 
  .u-section-2 .u-image-1 {
    width: 90%;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 5px 5px 24px 0 rgb(0 0 0 / 15%);
    /* margin: 0 auto; */
    margin-top: auto;
    margin-bottom: auto;
    float: right;
    margin: 82px auto 13px -5px;
    /* margin-top: 113px; */
  }

  .u-section-2 .u-layout-cell-2 {
    background: #eeeeef;
  }
}

@media only screen and (max-width: 600px) {
  .mision_img {
    width: 40%;
  }
  .our_mission {
    padding-top: 49px;
    color: #5a2424;
    font-size: 23px;
    font-weight: bold;
    padding-left: 13px;
    width: 80%;
  }
  .mision_img {
    width: 30%;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 14px;
  }
 
  .company_groups {
    display: block;
    margin-bottom: 276px;
  }
  .companies_1 {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 68%;
    height: 27%;
    border-radius: 1%;
  }
  .companies_2 {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 68%;
    height: 27%;
    border-radius: 2%;
  }
  .companies_3 {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 68%;
    height: 27%;
    border-radius: 2%;
  }
  .companies_4 {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 68%;
    height: 27%;
    border-radius: 2%;
  }
  .companies_5 {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 68%;
    height: 27%;
    border-radius: 2%;
  }
  .companies_6 {
    margin-bottom: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 68%;
    height: 27%;
    border-radius: 2%;
  }
  .company_name {
    font-size: 3vw;
  }
  .gofcom {
    font-size: 4.5vw;
  }
  .u-section-2 .u-container-layout-2 {
    display: none;
  }
  .u-section-2 .u-layout-cell-2 {
    min-height: 354px;
  }
  .u-section-2 .u-layout-cell-1 {
    min-height: 469px;
  }
}
