.product_list_body{
    width: 95%;
    height: 1000px;
    background-color: rgb(249, 241, 241);
    margin: auto;
    display: flex;
}
.pl_left_side{
    width: 30%;
    height:1000px;
    background-color: rgb(6 79 0);
    padding-top: 20px;
    padding-left: 20px;
}

.pl_right_side{
    width: fit-content;
    height: 1000px;
    overflow: scroll;
 
}
.link_item{
    color: rgb(255, 255, 255);
    text-decoration: none;
    font-size: 16px;
}
.link_item_names{
    margin-bottom: 7px;
}
 
.link_item_names +.active_category{
 font-weight: 800;
 

}
.active_category{
    font-weight: 800;
    
   
   }

.link_item:hover{
    color: white;
}
.p_category{
    padding-left: 10px;
    padding-top: 10px;
}
@media only screen and (max-width: 1199px) {
    .product_list_body{
        margin-top: 69px;
    }
}
@media only screen and (max-width: 768px) {
    .product_list_body{
        margin-top: 51px;
    }
    .link_item{
       
        font-size: 2vw;
    }
    .pl_left_side{
        width: 40%;
        
    }
}
@media only screen and (max-width: 600px) {
    .pl_left_side{
        width: 36%;
        
    }
    .link_item{
       
        font-size: 2vw;
    }
   
}