.topic_para {
  font-size: x-large;
  margin: auto;
  font: 1rem "Fira Sans", sans-serif;
}
.contact_body{
  width: 100%;
  height: fit-content;
  margin: auto;
  float: right;
  margin-top: 0PX;
 
  
}
.contact_contents{
 
  height: fit-content;
 
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  margin: auto;
}
.contact_boxes{
  width: 235px;
  height: 300px;
  
  margin: auto;
  padding: 20px;
  display: grid;

  padding-bottom: 20px;
}

.contact_img {
  width: 80%;
 
  height: fit-content;
  margin-top: -52px;
  float: right;
}
.contact_icon{
  width: 100px;
  height: 100px;
  z-index: 100;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.contact_topic{
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: #591400;
  
  text-align: center;
}
.contact_msg{
 text-align: center;
 font-size: 1VW;
 
}

.container{
  width: 1000px;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
  margin-top: 18vw;

}
.container, .card{
  position: relative;
  cursor: pointer;
  
}
.container, .card .face{
  width: 159px;
  height: 125px;
  transition: 0.5s;
}
.container{
  position: absolute;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
    width: -moz-fit-content;
    width: fit-content;
   
    margin-left: 7%;
    margin-top: 15%;
    left: 1%;
  
  
}
.card{
  position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgb(0 0 0 / 80%);
    transform: translateY(-100px);
}
.face1{
background-color: #591400;
}
.container,  .face,.face1{
  
  transform: translateY(0);
}
.top_box{
  background-image: linear-gradient(#fd9982, rgb(248, 183, 63));
  width: 100%;
  height: 100%;
  text-align: center;
}
.top_box:hover{
  background-image: #591400;
}
 .card, .face, .face2{
  position: relative;
  margin: 11px;

  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  box-sizing: border-box;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  transform: translateY(-100px);
}
.container, .card:hover, .face, .face2{
  transform: translateY(0);
}
.container .card .face.face2 .content p{
  margin: 0;
  padding: 5px;
  font-size: small;
  word-wrap: break-word;
  width: 129px;
  font-weight: bold;
}
.container .card .face.face1 .content{
  opacity: 0.6;
  transition: 0.5s;
}
.container .card:hover .face.face1 .content{
  opacity: 1;
}
.container .card .face.face1 .content img{
  max-width: 102px;
  margin: auto;
  margin-top: auto;

}
.container .card .face.face1 .content h3{
  margin: -14px 0 0;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 1.2em;
}
.container .card .face.face2 .content a{
  margin: 15px 0 0;
  display:  inline-block;
  text-decoration: none;
  font-weight: 900;
  color: #333;
  padding: 5px;
  border: 1px solid #333;
}
.container .card .face.face2 .content a:hover{
  background: #333;
  color: #fff;
}
@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);

.contact3 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
  padding: 30px;
}

.contact3 h1,
.contact3 h2,
.contact3 h3,
.contact3 h4,
.contact3 h5,
.contact3 h6 {
  color: #3e4555;
}

.contact3 .font-weight-medium {
  font-weight: 500;
}

.contact3 .card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.contact3 .btn-danger-gradiant {
  background: #ff4d7e;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #ff4d7e 0%,
    #ff6a5b 100%
  );

  background: -webkit-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: -o-linear-gradient(left, #ff4d7e 0%, #ff6a5b 100%);
  background: linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%);
  position: absolute;
  z-index: 2;
}

.contact3 .btn-danger-gradiant:hover {
  background: #ff6a5b;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #ff6a5b 0%,
    #ff4d7e 100%
  );

  background: -webkit-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: -o-linear-gradient(left, #ff6a5b 0%, #ff4d7e 100%);
  background: linear-gradient(to right, #ff6a5b 0%, #ff4d7e 100%);
}
.col-lg-4 {
  margin-top: 60px;
}
.form_of_cont {
  display: flex;
}
.errors {
  color: red;
}
@media only screen and (max-width: 943px) {
  .body_cont{
    margin-top: 400px;
    
  }
  .contact_body{
    margin-top: -350PX;
  }
 .card .face {
    
  }
  .container .card .face.face2 .content p {
   
    font-size: 11px;
    width: 129px;
    word-wrap: break-word;
}
.contact_img{
 /* width: 80%;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: -91px; 
    float: right;*/
    display: none;
}
.container{
  flex-wrap: wrap;
  width: 91%;
  position: relative;
  margin-bottom: -115px;
}
.contact_body{
  float: none;
}
}
@media only screen and (max-width: 500px){
  .body_cont {
    margin-top: 430px;
}
}