.Gallery_body {
  /* display: flex;
  color: black;
  height: fit-content;
  
  flex-wrap: wrap; */
  /* -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 5; */
  /* -webkit-column-width: 33%; */
  margin-top: 35px;

  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0 12px;
  display: inline-flex;
  flex-wrap: wrap;
}
.Gallery_area{
  width: 80%;
  height: fit-content;
  display: flex;
  margin: auto;
}
.Gallery_body .image {
  width: 23%;
  margin-right: 2%;
}

.left {
  width: 80%;
  background: yellow;
  height: 800px;

  margin: auto;
}
.image_topics {
  width: fit-content;
  height: 50px;

  display: flex;
  margin: auto;
}
.tag {
  border: none;
  color: rgb(161, 29, 29);
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  width: 150px;
  height: 50px;
}
.tag:hover {
  background-color: #b94a42;
  color: white;
}
.active {
  background-color: #b94a42;
  color: white;
}
.images_gallery {
  width: 100%;
 
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
  border: 1px solid #efefef;
  box-shadow: azure;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px; 
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .image_topics {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 768px) {
  .tag {
    margin-top: 36px;
  }
  .Gallery_body {
    margin-top: 54px;

    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
  }
  .tag {
    padding: 14px 16px;
    width: 98px;
    font-size: 13px;
  }
  .Gallery_body .image{
    width: 31%;
  }
  @media only screen and (max-width: 600px) {
    .Gallery_body .image {
      width: 48%;
    }
    .tag {
      font-size: 10px;
      width:68px;
      padding: 10px 1px;
      height: 40px;
      height: 33px;
    }
  }
}

.modalImage {
  width: 100%;
}

.closeIcon {
  background-color: white;
  border-radius: 50%;
  color: black;
  /* width: 20px;
  height: 50px; */

  /* background-color: white; */
  /* border-radius: 50%;
    color: black; */
  /* width: 20px; */
  /* height: 43px; */
  /* margin-left: 33px; */
  /* margin-top: -14px; */
}
