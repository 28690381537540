.product_item {
  width: 215px;
  height: 145px;
  background-color: rgb(23, 23, 22);
  margin: auto;
  margin-bottom: 49px;
  margin-right: 31px;
  box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
}
.product_image{
width: 215px;
  position: absolute;
        height: 145px;
    display: flex;
    opacity: 100%;
    
}
.product_image:hover{
  
}
.product_name{
  color: rgb(96 37 37);
    cursor: pointer;
    text-align: center;
    font-family: Verdana;
    font-weight: bold;
    z-index: 5;
    margin: auto;
    position: relative;
    margin-top: 76px;
    display: flex;
    /* margin-left: 24px; */
    font-size: 14px;
    background: #eae9eb;
    bottom: -21%;
    height: 39px;
    padding-left: 10px;
    padding-top: 10px;
    border: 1px solid #d5c3c3;
        
}
.product_name:hover{
  background-color: #8e8e8e;
  color:#eae9eb;
}


.product_list {
  width: 80%;
  height: 1000px;

  margin: auto;
}
.first_raw {
  height: 25%;
  width: 100%;

  display: flex;
}
.sec_raw {
  height: 25%;
  width: 100%;
  display: flex;
}
.third_raw {
  height: 25%;
  width: 100%;
  display: flex;
}
.fourth_raw {
  height: 25%;
  width: 100%;
  display: flex;
}
.fr1 {
  cursor: pointer;
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
}
.fr2 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
}
.fr3 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
  border-color: black;
}
.fr1_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}
.img1 {
  width: 100%;
  height: 100%;
  opacity: 35%;
}
.products_names {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: x-larges;
  color: aliceblue;
  cursor: pointer;
  text-align: center;
  font-family: Verdana;
}
.fr2_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}
.fr3_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}
.product_topic {
  font-family: "Bai Jamjuree", sans-serif;
  display: flex;
}
.product_topic_name {
 
  margin: auto;
  color: #591400;
  font-size: 28px;
  font-weight: bold;
  font-family: "Bai Jamjuree", sans-serif;
  margin-bottom: 20px;
}
.sr1 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
  border-color: #000000;
}
.sr2 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
  border-color: #000000;
}
.sr3 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
  border-color: #000000;
}
.sr1_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}
.sr2_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}

.sr3_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}
.tr1 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
}
.tr2 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
}
.tr3 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
  border-color: black;
}
.tr1_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}
.tr2_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}

.tr3_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}
.fourthr1 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
}
.fourthr2 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
}
.fourthr3 {
  height: 100%;
  width: 33.33%;
  float: left;
  display: flex;
  position: relative;
  border-color: black;
}
.fourthr1_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}
.fourthr2_img {
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: black;
  height: 80%;
  margin: auto;
}

.fourthr3_img {
}
.link {
  text-decoration: none;
  color: inherit;
  margin: auto;
}

.product_rack {
  flex-wrap: wrap;
  width: 81%;
 
  height: fit-content;
  display: flex;
  margin: auto;
}

@media only screen and (max-width: 1191px) {
  .product_topic_name{
    padding-top: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .fr1_img {
    width: 100%;
  }

  .fr2_img {
    width: 100%;
  }
  .fr3_img {
    width: 100%;
  }
  .products_names {
    left: 41%;
  }
  .link {
    margin-right: 31px;
  }
  .products_names {
    font-size: 2.4vw;
    top: 52%;
  }
  .product_topic_name {
    padding-top: 54px;
    margin-bottom: 5vw;
    font-size: 17px;
  }
  .first_raw {
    height: 10%;
    margin-bottom: 30px;
    margin-left: 15px;
  }
  .sec_raw {
    height: 10%;
    margin-bottom: 30px;
    margin-left: 15px;
  }
  .third_raw {
    height: 10%;
    margin-bottom: 30px;
    margin-left: 15px;
  }
  .fourth_raw {
    height: 10%;
    margin-bottom: 20px;
    margin-left: 15px;
  }
  .sr1_img {
    width: 100%;
  }
  .sr2_img {
    width: 100%;
  }
  .sr3_img {
    width: 100%;
  }
  .product_list {
    height: fit-content;
    width: 93%;
  }
  .tr1_img {
    width: 100%;
  }
  .tr2_img {
    width: 100%;
  }
  .tr3_img {
    width: 100%;
  }
  .fourthr1_img {
    width: 100%;
  }
  .fourthr2_img {
    width: 100%;
  }
}
@media only screen and (max-width: 625px) {
  .product_rack {
    
    width: 100%;
    
}
.product_item{
  margin-right: auto;
  margin-left: auto;
}

}
@media only screen and (max-width: 475px) {
  .product_rack {
    
    width: 70%;
    
}

}

